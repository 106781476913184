import {Moment} from 'moment';

export type Admin = {
  id: number;
  username: string;
  email: string;
  status: number;
  role: AdminRoles;
  phone: string;
  synagogues?: Synagogue[];
};

export enum AdminRoles {
  SUPERADMIN = 100,
  SYNAGOGUE = 10,
  PARTNER = 1,
}

export type Synagogue = {
  id: number;
  address: string;
  bank_account: string;
  status: number;
  name: string;
  charity_org_id: string;
  credit_card: string;
  phone: string;
  email: string;
  picture: string;
  admins: Admin[];
  total: number;
  type: number;
  country: string;
  city: string;
  street: string;
  house: string;
  member_name: string;
  member_id: string;
  member_id_date: Date | Moment;
  member_gender: number;
  member_dob: Date | Moment;
  member_phone: string;
  contact: string;
  bank_branch: string;
  bank_account_id: string;
  acquiring_account: number;
  acquiring_provider: string;
  acquiring_id: string;
  paragraf46: number;
  seller_dashboard_signup_link: string | null;

  partner: Partner | null;

  sygnature_first_name: string;
  sygnature_last_name: string;
  sygnature_phone: string;
  sygnature_email: string;
  sygnature_member_id: string;
  sygnature_member_dob: Date | Moment;
  sygnature_country: string;
  sygnature_city: string;
  sygnature_zip: string;
  sygnature_street: string;
  sygnature_house: string;
};

export type File = {
  id: number;
  uid: string;
  extention: string;
  size: number;
  title: string;
  name: string;
  url: string;
  field: string;
};

export type User = {
  id: number;
  username: string;
  status: string;
  email: string;
  created_at: string;
  total: number;
  avatar: string;
  referal: User;
  ref_count: number;
};

export type Donation = {
  id: number;
  amount: number;
  status: string;
  created_at: string;
  user: User;
  synagogye: Synagogue;
  order_id: number;
  order?: Order;
  text?: string;
};

export type Partner = {
  id: number;
  username: string;
  phone: string;
  email: string;
  status: number;
  created_at: number;
  slug: string;

  synagogues?: Synagogue[];
};

export type Order = {
  id: number;
  user_id: number;
  status: number;
  amount: number;
  currency: string;
  payment_info_id?: number;
  tsn_info?: string;
  invoice?: string;
  created_at: number;
  updated_at: number;
};

export type Banner = {
  id: number;
  title: string;
  image: any;
  from_date: string;
  to_date: string;
  go_to: string;
  created_at: number;
  updated_at: number;
  link: string;
  clicks_count: number;
  views_count: number;
};
export type Subsctiption = {
  id: number;
  created_at: number;
  updated_at: number;
  status: SubscriptionStatus;
  interval: SubsctiptionInterval;
  sunagogue_id: number;
  user_id: number;
  amount: number;
  currency: string;
};
export enum SubscriptionStatus {
  ACTIVE = 10,
  INACTIVE = 0,
}
export enum SubsctiptionInterval {
  DAY = 1,
  MONTH = 30,
}
